import { gql, useQuery } from "@apollo/client";
import {
  CompanyInfoResponse,
  CompanyInfoVariables,
} from "../../../types/company-info/companyInfoTypes";

const GET_COMPANY_INFO = gql`
  query GetCompanyInfo($id: String!) {
    companyInfo(where: { id: $id }) {
      id
      gmail
      number
      fbLink
      instaLink
      linkedLink
      whatsLink
      addressLink
      translations {
        id
        language
        address
        text
      }
    }
  }
`;

export const useGetCompanyInfo = () => {
  const { loading, error, data, refetch } = useQuery<
    CompanyInfoResponse,
    CompanyInfoVariables
  >(GET_COMPANY_INFO, {
    variables: { id: "e5fdd9c7-99a1-4f93-a42f-196726c2d68b" },
    skip: false,
  });

  return { loading, error, data, refetch };
};
