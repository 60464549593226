import { Spin, Alert, Card, Typography, Divider, Button } from "antd";
import { useGetCompanyInfo } from "../../graphql/queries/CompanyInfo/findCompanyInfo";
import { Link } from "react-router-dom";

const { Title, Paragraph, Text } = Typography;

const CompanyInfoComponent = () => {
  const { loading, error, data } = useGetCompanyInfo();

  if (loading) return <Spin tip="იტვირთება..." />;
  if (error)
    return (
      <Alert message="შეცდომა კომპანიის ინფორმაციის ჩატვირთვაში" type="error" />
    );

  return (
    <div style={{ maxWidth: 800, margin: "0 auto", padding: "20px" }}>
      <Title level={2}>სოციალური ლინკები / ინფორმაცია</Title>

      {data?.companyInfo && (
        <>
          <Card bordered={false} style={{ marginBottom: "20px" }}>
            <Paragraph>
              <Text strong>მეილი:</Text> {data.companyInfo.gmail}
            </Paragraph>
            <Paragraph>
              <Text strong>ტელეფონის ნომერი:</Text> {data.companyInfo.number}
            </Paragraph>
            <Paragraph>
              <Text strong>Facebook ლინკი:</Text> {data.companyInfo.fbLink}
            </Paragraph>
            <Paragraph>
              <Text strong>Instagram ლინკი:</Text> {data.companyInfo.instaLink}
            </Paragraph>
            <Paragraph>
              <Text strong>LinkedIn ლინკი:</Text> {data.companyInfo.linkedLink}
            </Paragraph>
            <Paragraph>
              <Text strong>WhatsApp ლინკი:</Text> {data.companyInfo.whatsLink}
            </Paragraph>
            <Paragraph>
              <Text strong>მისამართის ლინკი: </Text>
              {data.companyInfo.addressLink}
            </Paragraph>
          </Card>

          <Divider />

          <Title level={3}>თარგმანები</Title>
          {data?.companyInfo.translations.length > 0 ? (
            data.companyInfo.translations.map((translation) => (
              <Card key={translation.id} style={{ marginBottom: "10px" }}>
                <Title level={5}>
                  ენა: <Text>{translation.language}</Text>
                </Title>
                <Paragraph>
                  <Text strong>მისამართი:</Text> {translation.address}
                </Paragraph>
                <Paragraph>
                  <Text strong>ტექსტი:</Text> {translation.text}
                </Paragraph>
              </Card>
            ))
          ) : (
            <Text>თარგმანები არ მოიძებნა</Text>
          )}

          <Link to="/company-info/edit/e5fdd9c7-99a1-4f93-a42f-196726c2d68b">
            <Button type="primary" style={{ marginTop: "20px" }}>
              განახლება
            </Button>
          </Link>
        </>
      )}
    </div>
  );
};

export default CompanyInfoComponent;
