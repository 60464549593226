import React, { useState, useEffect } from "react";
import { Form, Input, Button, Card, Typography, message, Spin } from "antd";
import { useMutation } from "@apollo/client";
import { useGetCompanyInfo } from "../../graphql/queries/CompanyInfo/findCompanyInfo";
import { UPDATE_COMPANY_INFO } from "../../graphql/mutations/Company-info/updateCompanyInfo";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

const EditCompanyInfo = () => {
  const [form] = Form.useForm();
  const { data, loading: loadingData, error: fetchError } = useGetCompanyInfo();
  const navigate = useNavigate();
  const [updateCompanyInfo, { loading: updating, error: updateError }] =
    useMutation(UPDATE_COMPANY_INFO);

  const handleFinish = async (values: any) => {
    const updateData = {
      gmail: { set: values.gmail },
      number: { set: values.number },
      fbLink: { set: values.fbLink },
      instaLink: { set: values.instaLink },
      linkedLink: { set: values.linkedLink },
      whatsLink: { set: values.whatsLink },
      addressLink: { set: values.addressLink },
      translations: {
        update: values.translations.map((translation: any) => {
          const { language, id, address, text } = translation;
          return {
            where: { id },
            data: {
              address: { set: address },
              text: { set: text },
            },
          };
        }),
      },
    };

    try {
      const { data } = await updateCompanyInfo({
        variables: {
          id: "e5fdd9c7-99a1-4f93-a42f-196726c2d68b",
          data: updateData,
        },
      });
      if (data) {
        message.success("კომპანიის ინფორმაცია წარმატებით განახლდა!");
        navigate("/company-info");
      } else {
        throw new Error("Company info update failed");
      }
    } catch (err) {
      message.error(
        "შეცდომა კომპანიის ინფორმაციის განახლებაში: " +
          (err instanceof Error ? err.message : String(err))
      );
    }
  };

  if (loadingData) return <Spin tip="Loading Company Info..." />;
  if (fetchError) return <div>Error fetching company info</div>;

  return (
    <div style={{ maxWidth: 800, margin: "0 auto", padding: "20px" }}>
      <Title level={2}>კომპანიის ინფორმაციის განახლება</Title>
      <Card bordered={false}>
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            gmail: data?.companyInfo?.gmail,
            number: data?.companyInfo?.number,
            fbLink: data?.companyInfo?.fbLink,
            instaLink: data?.companyInfo?.instaLink,
            linkedLink: data?.companyInfo?.linkedLink,
            whatsLink: data?.companyInfo?.whatsLink,
            addressLink: data?.companyInfo?.addressLink,
            translations: data?.companyInfo?.translations.map(
              (translation: any) => ({
                language: translation.language,
                id: translation.id,
                address: translation.address,
                text: translation.text,
              })
            ),
          }}
          onFinish={handleFinish}
        >
          <Form.Item
            name="gmail"
            label="მეილი"
            rules={[{ required: true, message: "გთხოვთ შეავსოთ მეილის ველი!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="number"
            label="ტელეფონის ნომერი"
            rules={[
              {
                required: true,
                message: "გთხოვთ შეავსოთ ტელეფონის ნომერის ველი!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="fbLink"
            label="Facebook ლინკი"
            rules={[
              {
                required: true,
                message: "გთხოვთ შეავსოთ Facebook ლინკის ველი!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="instaLink"
            label="Instagram ლინკი"
            rules={[
              {
                required: true,
                message: "გთხოვთ შეავსოთ Instagram ლინკის ველი!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="linkedLink"
            label="LinkedIn ლინკი"
            rules={[
              {
                required: true,
                message: "გთხოვთ შეავსოთ LinkedIn ლინკის ველი!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="whatsLink"
            label="WhatsApp ლინკი"
            rules={[
              { required: true, message: "გთხოვთ შეავსოთ WhatsApp-ის ველი!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="addressLink"
            label="მისამართის ლინკი"
            rules={[
              { required: true, message: "გთხოვთ შეავსოთ მისამართის ლინკი!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.List
            name="translations"
            initialValue={data?.companyInfo?.translations.map(
              (translation: any) => ({
                language: translation.language,
                id: translation.id,
                address: translation.address,
                text: translation.text,
              })
            )}
          >
            {(fields, { add }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => (
                  <Card key={key} style={{ marginBottom: 16 }}>
                    <Title level={4}>Translation</Title>
                    <Form.Item
                      {...restField}
                      name={[name, "language"]}
                      label="ენა"
                      rules={[
                        { required: true, message: "Language is required" },
                      ]}
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "address"]}
                      label="მისამართი"
                      rules={[
                        { required: true, message: "Address is required" },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, "text"]}
                      label="ტექსტი"
                      rules={[{ required: true, message: "Text is required" }]}
                    >
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Card>
                ))}
              </>
            )}
          </Form.List>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={updating}>
              განახლება
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default EditCompanyInfo;
