"use client";
import { Fragment, useEffect, useState } from "react";
import { Form, Input, Button, Divider, Card, message, Upload } from "antd";
import { useParams, useNavigate } from "react-router-dom";
import { UploadChangeParam } from "antd/es/upload/interface";
import languages from "../../../constants/languages";
import { useGetOneAttribute } from "../../../graphql/queries/Products/Attributes/findAttribute";
import { useUpdateAttribute } from "../../../graphql/mutations/Product/Attribute/updateAttribute";
import { LanguageCode } from "../../../types/about-us/aboutUsTypes";
import { ProductAttributeTranslation } from "../../../types/product/productTypes";

const EditAttribute = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data } = useGetOneAttribute(id!);
  const { updateAttribute, loading: updating } = useUpdateAttribute(() =>
    navigate("/products")
  );
  const [form] = Form.useForm();
  const [translationMap, setTranslationMap] = useState<
    Record<LanguageCode, ProductAttributeTranslation | null>
  >({
    KA: null,
    EN: null,
    RU: null,
  });
  const [uploadedImage, setUploadedImages] = useState<string>();

  useEffect(() => {
    if (data) {
      const map: Record<LanguageCode, ProductAttributeTranslation | null> = {
        EN: null,
        RU: null,
        KA: null,
      };

      data.productAttribute.translations.forEach((translation: any) => {
        const languageCode = translation.language as LanguageCode;
        map[languageCode] = translation;
      });

      setTranslationMap(map);
      setUploadedImages(data.productAttribute.imageUrl);

      const formValues: any = {
        translations: data.productAttribute.translations.reduce(
          (acc: any, translation: any) => {
            acc[translation.language as LanguageCode] = {
              name: translation.name,
            };
            return acc;
          },
          {}
        ),
      };

      form.setFieldsValue(formValues);
    }
  }, [data, form]);

  const handleUpload = (info: UploadChangeParam) => {
    if (info.file.status === "done") {
      const newImageUrl = info.file.response.url;
      setUploadedImages(newImageUrl);
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };
  const attribute = data;
  const onFinish = async (values: any) => {
    const attributeData: any = {
      imageUrl: { set: uploadedImage },
      translations: {
        update: languages
          .map((language) => {
            const translation = translationMap[language.code as LanguageCode];
            if (!translation) {
              console.error(
                `No translation found for language code: ${language.code}`
              );
              return null;
            }
            const name = values.translations[language.code]?.name || "";

            if (!name) return null;
            return {
              where: { id: translation.id },
              data: {
                name: { set: name },
              },
            };
          })
          .filter(Boolean) as any,
      },
    };

    try {
      await fetch(
        `${process.env.REACT_APP_API_URL}/delete-image/${attribute.productAttribute.imageUrl}`,
        {
          method: "DELETE",
        }
      );
      const { data } = await updateAttribute({
        variables: { id: id || "", data: attributeData },
      });

      if (data) {
        message.success("Attribute Us updated successfully");
        navigate("/products");
      } else {
        throw new Error("Attribute Us update failed");
      }
    } catch (err) {
      message.error(
        "An error occurred: " +
          (err instanceof Error ? err.message : String(err))
      );
    }
  };

  return (
    <Fragment>
      <Card title="კომპანიის შესახებ ინფორმაციის რედაქტირება">
        <Form form={form} onFinish={onFinish}>
          <Form.Item name="images">
            <Upload
              action={`${process.env.REACT_APP_API_URL}/upload`}
              listType="picture"
              onChange={handleUpload}
              multiple
              showUploadList={false}
            >
              <Button>სურათის ატვირთვა</Button>
            </Upload>
          </Form.Item>

          <div
            style={{
              position: "relative",
              width: "100%",
              border: "1px solid #d9d9d9",
              borderRadius: 8,
              overflow: "hidden",
              padding: "8px 0 0 8px",
              height: "66px",
            }}
          >
            <img
              src={`${process.env.REACT_APP_API_URL}/uploads/${uploadedImage}`}
              style={{
                width: "47px",
                height: "auto",
                objectFit: "cover",
                display: "block",
              }}
            />
          </div>

          {languages.map((language) => (
            <Fragment key={language.code}>
              <Divider>{language.name}</Divider>
              <Form.Item
                name={["translations", language.code, "name"]}
                label="სახელი"
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ სათაური შეიყვანოთ სათაური",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Fragment>
          ))}

          {data?.productAttribute.user && (
            <>
              <Form.Item
                label="არსებობის წელი"
                name="existenceYear"
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ შეიყვანოთ კომპანიის არსებობის წელი",
                  },
                ]}
              >
                <Input placeholder="არსებობის წელი" />
              </Form.Item>
              <Form.Item
                label="მომხმარებლების რაოდენობა"
                name="user"
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ შეიყვანოთ მომხმარებლების რაოდენობა",
                  },
                ]}
              >
                <Input placeholder="მომხმარებლების რაოდენობა" />
              </Form.Item>
              <Form.Item
                label="მაღაზია/ფილიალი"
                name="magazine"
                rules={[
                  {
                    required: true,
                    message: "გთხოვთ შეიყვანოთ მაღაზია/ფილიალი",
                  },
                ]}
              >
                <Input placeholder="მაღაზია/ფილიალი" />
              </Form.Item>
            </>
          )}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginTop: "20px", backgroundColor: "#1890ff" }}
              loading={updating}
            >
              შენახვა
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </Fragment>
  );
};

export default EditAttribute;
